import React from 'react';
import axios from 'axios';

class List extends React.Component {

    getData() {
        console.log('get data');
        const url = "/api/v1/cryptocurrency/quotes/latest?id=1027,1839&CMC_PRO_API_KEY=eaf93f20-4819-4f6d-9bff-ced0cdf45356&convert=USDT";
        axios.get(url).then((result) => {
            console.log('---', result);
        }).catch(err => {
            console.log(err);
        })
    }

    constructor({}) {
        super({});
        
        this.getData();
    }

    render() {
      return <h1>list</h1>;
    }
  }


export default List;